.login {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-image: url('https://i.ibb.co/G0JK55V/restaurant-food-items-cover.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.full-height {
  height: 100%;
}

.login-content {
  text-align: center;
  padding: 20px; /* Optional: add padding to give the text and button some space */
  border-radius: 10px; /* Optional: round the corners of the overlay */
}

.logo {
  width: 248px;
  max-width: 500px;
}

.leave-review-msg {
  margin-top: -20px;
  font-size: 20px;
}

.signin-button {
  margin-top: 20px;
  margin-bottom: 40px;
}

/* Responsive styles */
@media (max-width: 768px) {
  .login-content {
    padding: 20px;
  }

  .logo {
    max-width: 300px;
  }

  .leave-review-msg {
    font-size: 16px;
  }
}
