.header-logo {
    width: 100%;
}

@media (max-width: 576px) {
    .responsive-header-nav {
        visibility: hidden;
    }

    .dc-nashua-sections {
        margin-top: -100px !important;
    }
}

.custom-nav-link {
    margin-left: 40px;
    margin-right: -20px;
    font-size: 18px;
    padding: 10px 20px;
    transition: color 0.3s, background-color 0.3s;
}

.download-btn {
    margin-left: 20px;
}

.active-link {
    background-color: #28a745;
    color: white !important;
    border-radius: 10px;
}