.visible-xs {
    display: none;
}

.hidden-xs {
    display: block;
}

.responsive-row {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #f0f0f0;
    padding: 16px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15); /* add this line */
}  

.responsive-cell {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.cell-title {
    font-weight: bold;
}

@media (max-width: 767px) {
    .visible-xs {
        display: block;
    }

    .hidden-xs {
        display: none;
    }
}