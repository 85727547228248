.cat-title {
    color: #fd590d;
    font-size: 40px !important;
}

.sold-out-menu-item-name {
    font-family: "Rockwell";
    opacity: 0.6;
}

.sold-out-menu-item-price-txt {
    color: #fd590d !important;
    font-family: "Rockwell";
    margin-top: 8px;
}

h4 {
    font-size: 38px !important;
}

.reduced-spacing {
    margin-bottom: -7.5px; /* Adjust this value to your desired spacing */
  }
  