.responsive-table-container {
    overflow-x: auto;
  }
  
  @media screen and (max-width: 768px) {
    .responsive-table-container {
      width: 100%;
      overflow-x: scroll;
    }
  }
  