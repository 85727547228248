.partyorders {
    margin: 24px;
}

.ant-modal-close-x {
    margin-left: -17px !important;
}

.antd-btn {
    background-color: #018900;
    color: #fff;
}

.antd-btn :hover {
    background-color: #018900 !important;
    color: #fff !important;
}

.header {
    border-radius: 10px;
}

.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

/* Mobile devices (portrait) */
@media only screen and (max-width: 767px) {
    .img-left {
        margin-top: 2px;
        width: 70%;
    }
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #fff !important;
}

.ant-tabs .ant-tabs-tab {
    color: gray;
    opacity: 0.9;
}

.ant-tabs .ant-tabs-tab :hover {
    color: gray;
    opacity: 0.9;
}

.ant-tabs-card.ant-tabs-top>.ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top>div>.ant-tabs-nav .ant-tabs-tab {
    border-radius: 8px;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active {
    background-color: #018900;
    color: #fff;
}

.ant-tabs-card>.ant-tabs-nav .ant-tabs-tab-active, .ant-tabs-card>div>.ant-tabs-nav .ant-tabs-tab-active :hover {
    background-color: #018900;
    color: #fff;
}