.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.login-page img {
  max-width: 250px;
  margin-bottom: 16px;
}

.login-page .ant-card {
  max-width: 350px;
  border-radius: 5px;
}

.login-page .ant-form-item {
  margin-bottom: 16px;
}

.login-page .ant-btn {
  width: 70%;
  margin: 0 auto; /* Center the button */
}

.login-page .btn-container {
  display: flex;
  justify-content: center;
}

.ant-layout {
  background: #fff;
}
